import React from "react";
import Img from "gatsby-image";

import { css } from "@emotion/core";

const styles = {
  imgBox: css`
    position: relative;
    overflow: hidden;
  `,
  img: css`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    transition-delay: 500ms;
  `,
  spacer: css`
    width: 100%;
    padding-bottom: 100%;
  `
};

const GatsbyImage = ({ className, fixed, fluid, aspectRatio, ...props }) => {
  if (fixed) {
    return <Img className={className} fixed={fixed} {...props} />;
  }

  if (fluid) {
    return (
      <Img
        className={className}
        fluid={{ ...fluid, ...(!!aspectRatio && { aspectRatio }) }}
        {...props}
        presentationWidth={fluid.presentationWidth}
      />
    );
  }

  return <></>;
};

const Image = ({ alt, childImageSharp, image, square, ...props }) => {
  const gatsbyImage =
    childImageSharp || (image && image.childImageSharp) || false;

  if (gatsbyImage) {
    return <GatsbyImage {...gatsbyImage} alt={alt} {...props} />;
  }

  if (typeof image === "string" && square) {
    return (
      <div className={props.className || undefined} css={styles.imgBox}>
        <div aria-hidden="true" css={styles.spacer}></div>
        <img src={image} alt={alt} css={styles.img} />
      </div>
    );
  }

  if (typeof image === "string") {
    return (
      <img src={image} alt={alt} className={props.className || undefined} />
    );
  }

  return <></>;
};

Image.defaultProps = {
  alt: ""
};

export default Image;
